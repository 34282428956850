.box>div {
  padding: 10px !important;
}
.tab .css-19kzrtu {
  padding: 0px!important;
}
.Othertab .css-19kzrtu {
  padding: 0px!important;
}

.box {
  scrollbar-width: thin;
  overflow-y: scroll;
  height: 102vh;

  display: block;
}

.box::-webkit-scrollbar-button {
  height: 100px;
  /* vertical scrollbar height */
}

.box::-webkit-scrollbar {

  width: 0.4em;
  display: block;


}

.box::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.box::-webkit-scrollbar-thumb {
  background-color: #888;
  height: 10vh !important;
}

.box::-webkit-scrollbar-thumb:hover {
  background-color: #555;

}

.scroolDem {
  scrollbar-width: thin;
  overflow-y: scroll;
  height: 95vh;
  overflow-x: hidden;

  display: block;
}

.scroolDem::-webkit-scrollbar-button {
  height: 100px;
  /* vertical scrollbar height */
}

.scroolDem::-webkit-scrollbar {

  width: 0.4em;
  display: none;


}

.scroolDem::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroolDem::-webkit-scrollbar-thumb {
  background-color: #888;
  height: 10vh !important;
}

.scroolDem::-webkit-scrollbar-thumb:hover {
  background-color: #555;

}


.scroolDemDashboard {
  scrollbar-width: thin;
  overflow-y: scroll;
  height: 88vh;

  overflow-x: hidden;

  display: block;
}

.scroolDemDashboard::-webkit-scrollbar-button {
  height: 100px;
  /* vertical scrollbar height */
}

.scroolDemDashboard::-webkit-scrollbar {

  width: 0.4em;
  display: none;


}

.scroolDemDashboard::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroolDemDashboard::-webkit-scrollbar-thumb {
  background-color: #888;
  height: 10vh !important;
}

.scroolDemDashboard::-webkit-scrollbar-thumb:hover {
  background-color: #555;

}





.scroolDemMobile {
  scrollbar-width: thin;
  overflow-y: scroll;
  height: 83vh;
  overflow-x: hidden;

  display: block;
}

.scroolDemMobile::-webkit-scrollbar-button {
  height: 100px;
  /* vertical scrollbar height */
}

.scroolDemMobile::-webkit-scrollbar {

  width: 0.4em;
  display: none;


}

.scroolDemMobile::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroolDemMobile::-webkit-scrollbar-thumb {
  background-color: #888;
  height: 10vh !important;
}

.scroolDemMobile::-webkit-scrollbar-thumb:hover {
  background-color: #555;

}

.boxTwo>div {
  padding: 0px 15px 15px 15px !important;
}

.boxTwo {
  scrollbar-width: thin;
  padding: 0px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 87vh;
  display: block;
}
.boxTwoTab {
  
  padding: 0px;
  height: 100px!important;

  /* height: 87vh; */
  display: block;
}


.boxTwo::-webkit-webkit-scrollbar-button {
  height: 100px;
  /* vertical scrollbar height */
}

.boxTwo::-webkit-scrollbar {

  width: 0.4em;
  display: block;


}

.boxTwo::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.boxTwo::-webkit-scrollbar-thumb {
  background-color: #888;
  height: 10vh !important;
}

.boxTwo::-webkit-scrollbar-thumb:hover {
  background-color: #555;

}


.boxThree>div {
  padding: 0px 15px 15px 15px !important;
}

.boxThree {
  scrollbar-width: thin;
  padding: 0px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 73vh;
  display: block;
}



.boxThree::-webkit-webkit-scrollbar-button {
  height: 100px;
  /* vertical scrollbar height */
}

.boxThree::-webkit-scrollbar {

  width: 0.4em;
  display: block;


}

.boxThree::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.boxThree::-webkit-scrollbar-thumb {
  background-color: #888;
  height: 10vh !important;
}

.boxThree::-webkit-scrollbar-thumb:hover {
  background-color: #555;

}

.drowTwo {
  /* transition: all 0.3s ease-out!important; */
  -webkit-transition: 3s;
  -moz-transition: 3s;
  -ms-transition: 3s;
  -o-transition: 3s;
  transition: 3s;
}



.MuiTableCell-root .MuiTableCell-body .MuiTableCell-alignLeft .MuiTableCell-sizeMedium .css-1ex1afd-MuiTableCell-root {
  padding: 0px;

  font-family: 'Inter';
}


.searh-input input::placeholder {
  color: white;
  opacity: 1;
}

/* MuiPaper-root MuiMenu-paper MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation8 MuiPopover-paper css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper
 */
.test {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  margin-bottom: 0px !important;
}



@media (min-width: 600px) {

  .css-16t5uud {
    /* max-width: 480px; */
    max-width: 100% !important;
  }
}

@media (max-width: 0px) {

  .css-16t5uud {

    max-width: 100% !important;
  }
}

.test .MuiPaper-root {
  opacity: 1;
  transform: none;
  transition: opacity 333ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 222ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  top: 0px !important;
  left: 67px !important;
  padding: 16px 19.2px !important;
  overflow-x: hidden;
  overflow-y: auto;
  width: 220px !important;
  height: 100% !important;
  max-height: 100%;
}


.test .MuiPaper-root::-webkit-scrollbar {
  width: .4em;
}

.test .MuiPaper-root::-webkit-scrollbar,
.test .MuiPaper-root::-webkit-scrollbar-thumb {
  overflow: visible;
  border-radius: 4px;
}

.test .MuiPaper-root::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, .2);
}




@media (max-width: 1084px) {
  .header {
    padding-left: 10px !important;
  }

  .right {
    font-size: 20px !important;
  }

  .left {
    font-size: 20px !important;
  }
}

body {
/* need to change */
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
}

body::-webkit-scrollbar {
  width: 0.4em;
  display: none;
}

body::-webkit-scrollbar-track {
  background: #fff,
}

/* body::-webkit-scrollbar-thumb{
  background-color: #888;
}
body::-webkit-scrollbar-thumb:hover{
  background: #555;
} */

.MenuItem {
  border-radius: 10px;
  height: 55px;
}

.MenuItem:hover {
  border-radius: 10px;

}

.MenuItemTwo:hover {
  border-radius: 7px;
}



.MuiTabScrollButton-root svg {
  font-size: 30px !important;
}

.blockStyle {
  display: block;
  column-gap: 20px;

}

.flexStyle {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: start;
  align-items: center;
  column-gap: 20px;
  margin-top: 20px
}

.blockS {
  padding-bottom: 15px;
}

.blockSReverse {
  padding-bottom: 0px;
}

.blockSs {
  font-weight: 400;
  font-size: 15px;
  color: #000000;
  margin: 0px;
  padding-bottom: 0px
}

.blockSReversee {
  font-weight: 400;
  font-size: 15px;
  color: #000000;
  margin: 0px;
  padding-bottom: 5px
}
.mobieFooter{
  display: none;
}
.mobilVied{
  display: none!important;
}
.chart{
  height:131px;
  width:84px;
 border:none;
 overflow-y:hidden;
}
.mobChart{
  display: grid;
  grid-template-columns: 54% 27%;
  justify-content: start;
  align-items:center;
  column-gap:10%;
}

@media (max-width: 1270px) {
.footerMain{
  display: none;
}
.chart{
  height:95px;
  width:84px;
 border:none;
 overflow-y:hidden;
}
.mobChart{
  display: grid;
  grid-template-columns: 55% 36%;
  justify-content: start;
  align-items:center;
  column-gap:13%;
}
.mobieFooter{
  display: block;
  box-shadow: 5px -3px 4px rgba(0, 0, 0, 0.25);
  display:grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  align-items: center;
  justify-content: center;
  bottom:0px;
  width:100%;
  max-height:100%;
 
  background-color: #fff;
 
  position:fixed;
  padding: 10px 0px;
}
.bellSec{
   text-align: end;
}
.webview{
  display: none!important;
}
.mobilVied{
  display: block!important;
}
.flexStyle {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: start;
  align-items: center;
  column-gap: 20px;
  margin: 0px;
}
body {
  /* overflow-y: hidden;
  overflow-x: hidden; */
  margin: 0px!important;
  padding: 0px !important;
}
.webView{
  display: none!important;
}
.css-cveggr-MuiListItemIcon-root {
  min-width: 35px!important;

}
.css-19kzrtu {
  padding: 0px!important;
}

.MuiTabScrollButton-root svg {
  font-size: 30px !important;
  color: white;
}
.dataStyle{
  font-size: 12px;
  font-weight:400;
  color: #757575;
  margin: 5px;
}

}


.custom-input::placeholder {
  color: black; /* Replace with your desired color */
}
.custom-input-diolog::placeholder {
  color:rgba(0, 0, 0, 0.25) 
   
}






.custom-button:hover {
  background-color: #DCDCDC;
 
}

/* .MuiTooltip-tooltip
 {
  
margin-bottom: -60px!important;

} */

.MuiTooltip-tooltip
 {
  
  font-size:13px!important;
  font-weight: 400;


  padding: 8px 10px!important;
  color: #fff;
  text-align: center;
  justify-content: center;
  text-decoration: none;

  background-color: rgba(0, 0, 0, 0.85)!important;
  border-radius: 6px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;


}

.MuiTooltip-arrow
 {color: black !important; 
/* Change this to the desired color */

}
.checkboxItem:hover {
  background-color: #E6F4FF;
  border-radius: 10px;
 
}




.testClass1{
  background: gainsboro;
}

.testClass2{
  background: none;
}
.css-1ndpvdd-MuiTableCell-root {
  
  padding: 0px!important;
 
}


.TDRow .css-1ex1afd-MuiTableCell-root {

  padding: 10px;

}

.TR .css-1ygcj2i-MuiTableCell-root {

  padding: 8px;
  
}
.underline-input {
  text-decoration: underline;
}


#combo-box-demo .MuiSvgIcon-root {
  margin-right: 20px!important;
}


.parent .child {
  background-color:rgba(217, 217, 217, 0.50);
  color: rgba(0, 0, 0, 0.87);
 box-shadow: none!important;
  width: 300px;
  margin-right: 13px!important;
  padding:0px!important;
 
}

.parent .cardChild {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
border-radius: 0px!important;
  margin: 20px 15px;
  /* height: 230px!important; */
  padding: 10px 15px;
}

main{
  overflow-x: scroll !important;
}


main::-webkit-scrollbar-button {
  height: 100px!important;
  /* vertical scrollbar height */
}

main::-webkit-scrollbar {

  width: 0.4em!important;
  display: block!important;


}

main::-webkit-scrollbar-track {
  background: #f1f1f1!important;
}

main::-webkit-scrollbar-thumb {
  background-color: #888!important;
  height: 10vh !important;
}

main::-webkit-scrollbar-thumb:hover {
  background-color: #555!important;

}
.cardScrool{
  
  overflow-y: scroll !important;
}

.cardScrool::-webkit-scrollbar {
  width: 0.4em!important;
  display: block!important;
}

.cardScrool::-webkit-scrollbar-thumb {
  background-color: #888!important;

}

.cardScrool::-webkit-scrollbar-thumb:hover {
  background-color: #555!important;

}


